<template>
  <div v-if="hasLinks">
    <BlockTitle :center="props.align === 'Center'" :subtitle :title />

    <div :class="containerClasses">
      <div
        v-for="(relatedLink, index) in parsedRelatedLinks"
        :key="`${relatedLink.label}_${id}`"
        :class="getCTAColors(relatedLink.mood).wrapper"
      >
        <RevButton
          class="shadow-short rounded-round !py-8 !pl-16"
          :class="getButtonClasses(relatedLink.mood, !!relatedLink.icon)"
          :rel="relatedLink.link?.rel"
          :size="relatedLinkSize"
          :target="relatedLink.link?.target"
          :to="relatedLink.link?.href"
          variant="primary"
          @click="relatedLinkClick(index, relatedLink.label)"
        >
          <span class="flex items-center">
            <span :class="getTextClasses(relatedLink.mood)">
              {{ relatedLink.label }}
            </span>

            <DynamicIcon
              v-if="relatedLink.icon"
              class="ml-8"
              :class="[
                getTextClasses(relatedLink.mood),
                relatedLinkSize === 'small' ? '!size-20' : '!size-24',
              ]"
              v-bind="relatedLink.icon"
            />
          </span>
        </RevButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, useCssModule } from 'vue'

import type { RelatedLink } from '@backmarket/http-api/src/api-specs-content/models/related-link'
import type { RelatedLinkProps } from '@backmarket/http-api/src/api-specs-content/models/related-links-content'
import type { CTAStyling } from '@backmarket/http-api/src/api-specs-content/shared/cta-styling'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { tw } from '@backmarket/utils/string/tw'
import { RevButton } from '@ds/components/Button'

import BlockTitle from '@backmarket/nuxt-layer-cms/BlockTitle.vue'
import type { ContentBlockProps } from '@backmarket/nuxt-layer-cms/models/content-block'

import DynamicIcon from '../../shared-components/DynamicIcon/DynamicIcon.vue'
import { getCTAColors } from '../../utils/CTAColorCombinations'

export type RelatedLinksProps = ContentBlockProps & RelatedLinkProps

const props = withDefaults(defineProps<RelatedLinksProps>(), {
  title: '',
  subtitle: '',
  size: 'Small',
  display: 'Wrap',
  align: 'Left',
})

const { trackClick } = useTracking()
const classes = useCssModule()

// This event is common to every CMS block in order to hide the block containers
// on demand
defineEmits(['error'])

const hasLinks = computed(() => props.links?.length > 0)

const parsedRelatedLinks = computed<RelatedLink[]>(() => {
  const relatedLinks: RelatedLink[] = []

  for (let i = 0; i < props.links.length; i += 1) {
    const relatedLink = props.links[i]
    if (!relatedLink.label || !relatedLink.link) continue

    relatedLinks.push({
      label: relatedLink.label,
      link: relatedLink.link,
      icon: 'icon' in relatedLink ? relatedLink.icon : undefined,
      mood: 'mood' in relatedLink ? relatedLink.mood : 'primary',
    })
  }

  return relatedLinks
})

const relatedLinkSize = computed(() => {
  return props.size === 'Small' ? 'small' : 'medium'
})

const containerClasses = computed(() => {
  return [
    tw`flex gap-8 pb-4 -mb-4 pl-24 -ml-24`,
    props.align === 'Center' && classes.safeCenter,
    props.display === 'Wrap' && 'flex-wrap',
    props.display === 'Inline-Scroll' &&
      '-mr-24 flex-nowrap overflow-x-auto scrollbar-none pr-24 md:mr-0 md:flex-wrap md:pr-0',
  ]
})

function relatedLinkClick(index: number, name: string) {
  trackClick({ ...props.tracking, position: index + 1, name })
}

function getButtonClasses(ctaStyling: CTAStyling, hasIcon: boolean) {
  const buttonClasses = getCTAColors(ctaStyling).button
  const height = relatedLinkSize.value === 'small' ? tw`h-36` : tw`h-40`
  const padding = hasIcon ? tw`pr-12` : tw`pr-16`

  if (ctaStyling === 'primary' || ctaStyling === 'secondary') {
    return [buttonClasses, height, padding]
  }

  return [
    tw`!bg-local-cms-action-brand hover:!bg-local-cms-action-brand-hover`,
    buttonClasses,
    height,
    padding,
  ]
}

function getTextClasses(ctaStyling: CTAStyling) {
  const textClasses = getCTAColors(ctaStyling).text

  if (ctaStyling === 'primary' || ctaStyling === 'secondary') {
    return textClasses
  }

  return [tw`text-local-cms-brand`, textClasses]
}
</script>

<style module>
.safeCenter {
  justify-content: safe center;
}
</style>
